<template>
  <div id="chartbox"></div>
</template>
<script>
export default {
  props: {
    chartData: {
      type: Object,
      default: () => {return {}},
    },
  },
  data() {
    return {
    };
  },
  created(){
    setTimeout(() => {
      this.renderEcharts();
    }, 500);
    
  },
  
  methods: {
    //加载echarts图表
    renderEcharts(){
      let myChart = this.$echarts.init(document.getElementById('chartbox'));
      let option;
      option = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            crossStyle: {
              color: '#999'
            }
          }
        },
       
        legend: {
          data: ['内资企业个数', '外商投资个数', '港澳台投资企业个数', '房地产企业合计个数']
        },
        xAxis: [
          {
            type: 'category',
            data: this.chartData.years,
            axisPointer: {
              type: 'shadow'
            }
          }
        ],
        yAxis: [
          {
            type: 'value',
            name: '企业个数',
            axisLabel: {
              formatter: '{value}'
            }
          },
          {
            type: 'value',
            name: '合计个数',
            axisLabel: {
              formatter: '{value}'
            }
          }
        ],
        series: [
          {
            name: '内资企业个数',
            type: 'bar',
            data: this.chartData.enterpriseDomesticNums,
            stack: 'x'
          },
          {
            name: '外商投资个数',
            type: 'bar',
            data: this.chartData.enterpriseForeignNums,
            stack: 'x'
          },
          {
            name: '港澳台投资企业个数',
            type: 'bar',
            data: this.chartData.enterpriseHkmtNums,
            stack: 'x'
          },
          {
            name: '房地产企业合计个数',
            type: 'line',
            yAxisIndex: 1,
            data: this.chartData.enterpriseNums
          }
        
        ]
      };

      myChart.setOption(option);
    }
    
  },
};
</script>
<style lang="less" scoped>
#chartbox{
  width: 100%;
  height: 100%;
  padding-left: 10px;
  padding-top: 10px;
}

</style>