<template>
    <!--区域分析-->
    <div class="base-container">
        <div class="main-wrapper">
            <el-container style="height: 100%; border: 1px solid #eee">
                <el-aside width="320px" style="background-color: rgb(255, 255, 255)">
                    <el-menu :default-openeds="['1', '2']" default-active="1-1">
                    <el-submenu index="1">
                        <template slot="title">人口与面积</template>
                        <el-menu-item index="1-1" @click="titleTab('popular')">人口与面积、土地出售面积</el-menu-item>
                        <el-menu-item index="1-2" @click="titleTab('stock')">存量面积</el-menu-item>
                        <el-menu-item index="1-3" @click="titleTab('populationRatio')">人口比例和自然增长率</el-menu-item>
                    </el-submenu>
                    <el-submenu index="2">
                        <template slot="title">区域与行业</template>
                        <el-menu-item index="2-1" @click="titleTab('taxRevenue')">房地产主营业务税金及附加占税收收入占比</el-menu-item>
                        <el-menu-item index="2-2" @click="titleTab('enterprise')">房地产企业</el-menu-item>
                        <el-menu-item index="2-3" @click="titleTab('building')">建设规模</el-menu-item>
                        <el-menu-item index="2-4" @click="titleTab('saleArea')">房屋销售</el-menu-item>
                        <!-- <el-menu-item index="2-5">利润总额</el-menu-item> -->
                    </el-submenu>
               
                    </el-menu>
                </el-aside>
                
                <el-container>
                    <el-main>
                        <div class="chart_box">
                            <populationChart  v-if="activeName == 'popular'" :chartData="chartData"></populationChart>
                            <stockChart  v-if="activeName == 'stock'" :chartData="chartData"></stockChart>
                            <populationRatioChart  v-if="activeName == 'populationRatio'" :chartData="chartData"></populationRatioChart>
                            <taxRevenueChart  v-if="activeName == 'taxRevenue'" :chartData="chartData"></taxRevenueChart>
                            <enterpriseChart  v-if="activeName == 'enterprise'" :chartData="chartData"></enterpriseChart>
                            <buildingChart  v-if="activeName == 'building'" :chartData="chartData"></buildingChart>
                            <saleAreaChart  v-if="activeName == 'saleArea'" :chartData="chartData"></saleAreaChart>
                        </div>
                    </el-main>
                </el-container>
            </el-container>
        </div>
    </div>
</template>

<script>

import {getStore,setStore,getLocalStore} from "@/util/store";
import {mapMutations} from "vuex";
import populationChart from '@/views/regional/populationChart.vue'
import stockChart from '@/views/regional/stockChart.vue'
import populationRatioChart from '@/views/regional/populationRatioChart.vue'
import taxRevenueChart from '@/views/regional/taxRevenueChart.vue'
import enterpriseChart from '@/views/regional/enterpriseChart.vue'
import buildingChart from '@/views/regional/buildingChart.vue'
import saleAreaChart from '@/views/regional/saleAreaChart.vue'

export default {
    name: "index",
    components: {
        populationChart: populationChart,
        stockChart: stockChart,
        populationRatioChart: populationRatioChart,
        taxRevenueChart: taxRevenueChart,
        enterpriseChart: enterpriseChart,
        buildingChart: buildingChart,
        saleAreaChart: saleAreaChart
    },

    data() {
        return {
            activeName: 'popular',
            num: {
                unitCount: 0,
                projectCount: 0
            },
            chartData:{}
        }
    },
    created() {
        this.getChartData('popular');
        this.getReport(0,'','');
    },
    destroyed(){
        this.getReport(1,'','');
    },
    mounted() {
        
    },
    methods: {
        ...mapMutations(["reducePath", "addMenuList", "reduceMenuList"]),
        //展现点击上报
        getReport(recordType,modelName,clickName){
            this.axios.request({
                method: "post", 
                url: '/dms/saveUserBehaviorRecord',
                 
                data: {
                    pageName:'regional',
                    userName:getLocalStore('areainfo').userName,
                    recordType:recordType,
                    modelName:modelName,
                    clickName:clickName,
                    userId:getLocalStore('areainfo').id,
                    ipAddress:getLocalStore('areainfo').ipAddress,
                    userAddress:getLocalStore('areainfo').userAddress,
                    ubr: getStore('reportobj')||{},
                },
            }).then((res) => {
               setStore('reportobj',res.data.data)
            })
        },
        titleTab(title) {
            if (title == 'popular') {
                this.getChartData(title);
                this.getReport(2,'menuNav','populationArea');
            } else if (title == "stock") {
                this.getStockData(title);
                this.getReport(2,'menuNav','stockArea');
            } else if (title == "populationRatio") {
                this.getPopulationRatioData(title);
                this.getReport(2,'menuNav','populationRate');
            } else if (title == "taxRevenue") {
                this.getTaxRevenueData(title);
                this.getReport(2,'menuNav','taxRevenue');
            } else if (title == "enterprise") {
                this.getEnterpriseData(title);
                this.getReport(2,'menuNav','enterprise');
            } else if (title == "building") {
                this.getBuildingData(title);
                this.getReport(2,'menuNav','building');
            } else if (title == "saleArea") {
                this.getSaleAreaData(title);
                this.getReport(2,'menuNav','saleArea');
            }
            
        },
        //获取人口面积数据
        getChartData(title){
            this.axios.request({
                loading: true,
                method: "post", 
                url: '/dms/getPopulationArea',
                 
            }).then((res) => {
                this.chartData = res.data.data;
                this.activeName = title;
            })
        },
        //获取存量面积数据
        getStockData(title){
            this.axios.request({
                loading: true,
                method: "post", 
                url: '/dms/getStockArea',
                 
            }).then((res) => {
                this.chartData = res.data.data;
                this.activeName = title;
            })
        },
        //获取人口比例数据
        getPopulationRatioData(title){
            this.axios.request({
                loading: true,
                method: "post", 
                url: '/dms/getPopulation',
                 
            }).then((res) => {
                this.chartData = res.data.data;
                this.activeName = title;
            })
        },
        //获取房地产主营业务税金及附加占税收收入占比
        getTaxRevenueData(title){
            this.axios.request({
                loading: true,
                method: "post", 
                url: '/dms/getTaxRevenue',
                 
            }).then((res) => {
                this.chartData = res.data.data;
                this.activeName = title;
            })
        },
        //获取房地产企业个数
        getEnterpriseData(title){
            this.axios.request({
                loading: true,
                method: "post", 
                url: '/dms/getEnterprise',
                 
            }).then((res) => {
                this.chartData = res.data.data;
                this.activeName = title;
            })
        },
        //获取建筑规模数据
        getBuildingData(title){
            this.axios.request({
                loading: true,
                method: "post", 
                url: '/dms/getBuilding',
                 
            }).then((res) => {
                this.chartData = res.data.data;
                this.activeName = title;
            })
        },
        //获取房屋销售数据
        getSaleAreaData(title){
            this.axios.request({
                loading: true,
                method: "post", 
                url: '/dms/getSaleArea',
                 
            }).then((res) => {
                this.chartData = res.data.data;
                this.activeName = title;
            })
        },
       
    }
}
</script>

<style scoped lang="less">
::-webkit-scrollbar {
    width: 0;
}
.main-wrapper{
    height: 100%;
}
.el-submenu__title{
    font-size: 16px !important;
    color: #323233;
}
.el-menu-item.is-active{
    background: #EDF4FF !important;
    color: #185BCE !important;
}
.chart_box{
    width: 100%;
    height: 100%;
    background: #ffffff;
}
.el-main{
  padding: 0 !important;
  overflow: hidden;
}


</style>