<template>
  <div id="chartbox"></div>
</template>
<script>
export default {
  props: {
    chartData: {
      type: Object,
      default: () => {return {}},
    },
  },
  data() {
    return {
    };
  },
  created(){
    setTimeout(() => {
      this.renderEcharts();
    }, 500);
    
  },
  
  methods: {
    //加载echarts图表
    renderEcharts(){
      let myChart = this.$echarts.init(document.getElementById('chartbox'));
      let option;
      option = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            crossStyle: {
              color: '#999'
            }
          }
        },
       
        legend: {
          data: ['销售住宅面积', '商业用房面积', '办公楼面积', '其他面积', '商品房销售总面积']
        },
        xAxis: [
          {
            type: 'category',
            data: this.chartData.years,
            axisPointer: {
              type: 'shadow'
            }
          }
        ],
        yAxis: [
          {
            type: 'value',
            name: '面积/万平方米',
            axisLabel: {
              formatter: '{value}'
            }
          },
          {
            type: 'value',
            name: '总面积/万平方米',
            axisLabel: {
              formatter: '{value}'
            }
          }
        ],
        series: [
          {
            name: '销售住宅面积',
            type: 'bar',
            data: this.chartData.soldResidentials,
            stack: 'x'
          },
          {
            name: '商业用房面积',
            type: 'bar',
            data: this.chartData.commercializedBusiness,
            stack: 'x'
          },
          {
            name: '办公楼面积',
            type: 'bar',
            data: this.chartData.commercializedOffices,
            stack: 'x'
          },
          {
            name: '其他面积',
            type: 'bar',
            data: this.chartData.commercializedOthers,
            stack: 'x'
          },
          {
            name: '商品房销售总面积',
            type: 'line',
            yAxisIndex: 1,
            data: this.chartData.soldSpaces
          }
        
        ]
      };

      myChart.setOption(option);
    }
    
  },
};
</script>
<style lang="less" scoped>
#chartbox{
  width: 100%;
  height: 100%;
  padding-left: 10px;
  padding-top: 10px;
}

</style>